<template>
  <div class="card">
    <div class="card-header">
      <div>
        <div class="card-title">{{ $t('page.settings.profile_logo.title') }}</div>
        <small>{{ $t('page.settings.profile_logo.description') }}</small>
      </div>
    </div>

    <div class="card-body">
      <div class="space-y-6 w-full">
        <div class="grid grid-cols-1 md:grid-cols-2 md:w-4/5 gap-4 mx-auto">
          <div>
            <label class="block text-center sm:text-left text-gray-600 py-1">
              {{ $t('page.settings.profile_logo.sentence.logo') }}
            </label>
            <div class="logo-wrapper-light flex justify-center items-center h-48">
              <img
                class="object-contain h-full w-full"
                :src="form.logo"
              >
            </div>
            <div class="flex justify-center mt-4">
              <base-uploader
                :path="`${basePath}/logo`"
                type="image"
                :maxSizeMb="10"
                :dimensions="{ width: 600, height: 600 }"
                @update:downloadUrl="updateLogoPath"
              >
                <template v-slot:button-content>
                  <i class="fas fa-cloud-upload-alt"></i> {{ $t('action.select_image') }}
                </template>
              </base-uploader>
            </div>
          </div>
          <div>
            <label class="block text-center sm:text-left text-gray-600 py-1">
              {{ $t('page.settings.profile_logo.sentence.ogo_reverse') }}
            </label>
            <div class="logo-wrapper-dark flex justify-center items-center h-48">
              <img
                class="object-contain h-full w-full"
                :src="form.logoReverse"
              >
            </div>
            <div class="flex justify-center mt-4">
              <base-uploader
                :path="`${basePath}/reverse-logo`"
                type="image"
                :maxSizeMb="10"
                :dimensions="{ width: 600, height: 600 }"
                @update:downloadUrl="updateReverseLogoPath"
              >
                <template v-slot:button-content>
                  <i class="fas fa-cloud-upload-alt"></i> Selecionar imagem
                </template>
              </base-uploader>
            </div>
          </div>
        </div>
      
        <ul
          class="bg-blue-100 rounded p-4 text-sm"
          v-html="$t('page.settings.profile_logo.sentence.use_logo_helper-html')"
        />
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import { defineComponent, ref, toRaw, unref } from 'vue'
import { useProfile } from '@/composables/profile/useProfile'
import { useProfileService } from '@/services/firestore/profile'
import BaseUploader from '../base/BaseUploader.vue'

export default defineComponent({
  components: { BaseUploader },

  setup () {

    const { profile } = useProfile()

    const { updateProfile } = useProfileService()

    const {
      id,
      user,
      logo = '',
      logoReverse = '',
    } = unref(profile)

    const form = ref({
      id,
      user,
      logo,
      logoReverse
    })

    const basePath = `profile/${user}/${id}`

    const formBuffer = ref({ ...form.value })

    const updateLogoPath = async (downloadUrl: string) => {
      form.value.logo = downloadUrl
      formBuffer.value = { ...form.value }

      await updateProfile(toRaw(form.value))
    }

    const updateReverseLogoPath = async (downloadUrl: string) => {
      form.value.logoReverse = downloadUrl
      formBuffer.value = { ...form.value }

      await updateProfile(toRaw(form.value))
    }

    return {
      form,
      basePath,
      updateLogoPath,
      updateReverseLogoPath
    }
  }
})
</script>

<style scoped>
.logo-wrapper-light {
  padding: 0.25rem;
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(228, 228, 231, var(--tw-bg-opacity));
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(228, 228, 231, var(--tw-border-opacity));
}
.logo-wrapper-dark {
  padding: 0.25rem;
  border-radius: 0.25rem;
  --tw-bg-opacity: 1;
  background-color: rgba(63, 63, 70, var(--tw-bg-opacity));
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(63, 63, 70, var(--tw-border-opacity));
}
</style>
